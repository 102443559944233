import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import HeroTypeC from '../../components/Heroes/HeroTypeC';
import BannerTypeColored from '../../components/Banners/BannerTypeColored';
import BannerInfoTypeA from '../../components/Banners/BannerInfoTypeA';
import TripleBanner from '../../components/Location/TripleBanner';
import MultiArticleV2 from '../../components/Articles/MultiArticleV2';
import FaqSection from '../../components/FAQ/FaqSection';
import BannerTypeA from '../../components/Banners/BannerTypeA';
import SEO from '../../components/SEO/SEO';

export default function BarrieDelivery({ data }) {
  const hero_type_c = data.strapiBarrieDelivery.Hero;
  const info_banner_colored = data.strapiBarrieDelivery.Info_Banner_Type_C;
  const info_banner_type_a = data.strapiBarrieDelivery.Info_Banner_Type_A;
  const triple_banner = data.strapiBarrieDelivery.Triple_Info_Banner_Section;
  const multi_article = data.strapiBarrieDelivery.Multi_Article_Section;
  const faq = data.strapiBarrieDelivery.FAQ;
  const banner_type_bottom = data.strapiBarrieDelivery.Blog_Banner_Bottom;

  return (
    <>
      <SEO
        title="Weed Delivery in Barrie | House of Cannabis"
        description="Tired of visiting random cannabis stores in Barrie? 
                  House of Cannabis is here to bring our cannabis products and weed accessories to right your doorstep."
      />
      <HeroTypeC
        title={hero_type_c.Title}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />

      <BannerTypeColored
        description={info_banner_colored.Content}
        buttonLabel={info_banner_colored.Button.Label}
        buttonLink={info_banner_colored.Button.Custom_Link_Slug}
        center
      />

      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />

      <TripleBanner
        title={triple_banner.Title}
        banners={triple_banner.Single_Triple_Info_Banner}
      />

      <MultiArticleV2
        leftarticle={multi_article.Left_Mutli_Article}
        rightarticle={multi_article.Right_Multi_Article}
      />

      <FaqSection faqdata={faq.FAQs} title={faq.Title} />

      <BannerTypeA
        title={banner_type_bottom.Title}
        description={banner_type_bottom.Content}
        bannerImage={
          banner_type_bottom.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/blog/"
        buttonLabel="CHECK OUT BLOGS"
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiBarrieDelivery {
      Hero {
        Content
        Title
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Info_Banner_Type_C {
        Button {
          Custom_Link_Slug
          Label
        }
        Content
      }
      Info_Banner_Type_A {
        Content
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Triple_Info_Banner_Section {
        Title
        Single_Triple_Info_Banner {
          Content
          Header
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      FAQ {
        Title
        FAQs {
          Answer
          Question
        }
      }
      Blog_Banner_Bottom {
        Content
        Title
        Blog_Button_Link {
          Button_Label
          Link_Slug
        }
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Multi_Article_Section {
        Left_Mutli_Article {
          article {
            Preview_Text
            Title
            slug
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        Right_Multi_Article {
          article {
            Title
            Preview_Text
            slug
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;
